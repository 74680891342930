import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';

const StyledChartWrapperSmall = styled('div')(({ theme }) => ({
  height: 372,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: 372 },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: 72,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${372 - 72}px) !important`,
  },
}));

const StyledChartWrapperBig = styled('div')(({ theme }) => ({
  height: 380,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: 380 },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: 100,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${380 - 100}px) !important`,
  },
}));

PieChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

const chartOptions = (chartColors, chartLabels, theme) => {
  return {
    colors: chartColors,
    labels: chartLabels,

    stroke: { colors: [theme.palette.background.paper] },

    legend: {
      floating: true,
      horizontalAlign: 'center',
      show: true,
      fontSize: String(13),
      position: 'top',
      markers: {
        radius: 12,
      },
      fontWeight: 500,
      itemMargin: { horizontal: 12 },
      labels: {
        colors: theme.palette.text.primary,
      },
    },

    dataLabels: {
      enabled: true,
      dropShadow: { enabled: false },
      style: {
        fontSize: "13px",
        colors: [theme.palette.text.primary],
      },
    },

    tooltip: {
      fillSeriesColor: true,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },

    plotOptions: {
      pie: {
        dataLabels: {
          offset: 40, // Moves the labels away from the chart
          minAngleToShowLabel: 1, // Only shows labels on slices with a certain angle size
        },
        donut: { labels: { show: false } },
      },
    },

    responsive: [
      {
        // sm
        breakpoint: theme.breakpoints.values.sm,
        options: {
          plotOptions: { bar: { columnWidth: '40%' } },
        },
      },
      {
        // md
        breakpoint: theme.breakpoints.values.md,
        options: {
          plotOptions: { bar: { columnWidth: '32%' } },
        },
      },
    ],

    radar: {
      polygons: {
        fill: { colors: ['transparent'] },
        strokeColors: theme.palette.divider,
        connectorColors: theme.palette.divider,
      },
    },

    tooltip: {
      x: {
        show: false,
      },
    },

    // Chart
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      // animations: { enabled: false },
      foreColor: theme.palette.text.disabled,
      fontFamily: theme.typography.fontFamily,
    },

    // States
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.04,
        },
      },
      active: {
        filter: {
          type: 'darken',
          value: 0.88,
        },
      },
    },

    colors: [
      theme.palette.primary.main,
      theme.palette.warning.main,
      theme.palette.info.main,
      theme.palette.error.main,
      theme.palette.success.main,
      theme.palette.warning.dark,
      theme.palette.success.darker,
      theme.palette.info.dark,
      theme.palette.info.darker,
    ],

    // Stroke
    stroke: {
      width: 1,
      curve: 'smooth',
      lineCap: 'round',
    },

    // Grid
    grid: {
      strokeDashArray: 3,
      borderColor: theme.palette.divider,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },

    // Xaxis
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false },
    },

    // Markers
    markers: {
      size: 0,
      strokeColors: theme.palette.background.paper,
    },

  }
};

export function PieChart({ title, subheader, chartColors, chartData, ...other }) {
  const theme = useTheme();

  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  return (
    <>
      <CardHeader title={title} subheader={subheader} />
      <StyledChartWrapperSmall dir="ltr">
        <ReactApexChart type="pie" series={chartSeries} options={chartOptions(chartColors, chartLabels, theme)} height={280} />
      </StyledChartWrapperSmall>
    </>
  );
}

export function PieChartForBankDisposed({ title, subheader, chartColors, chartData, ...other }) {
  const theme = useTheme();

  const chartLabels = chartData.map((i) => i.currentLoanProcessedBank);

  const chartSeries = chartData.map((i) => i.noOfFilesDisposed);

  return (
    <>
      <CardHeader title={title} subheader={subheader} />
      <StyledChartWrapperBig dir="ltr">
        <ReactApexChart type="pie" series={chartSeries} options={chartOptions(chartColors, chartLabels, theme)} height={280} />
      </StyledChartWrapperBig>
    </>
  );
}

export function PieChartForBankDisposedAmount({ title, subheader, chartColors, chartData, ...other }) {
  const theme = useTheme();

  const chartLabels = chartData.map((i) => i.currentLoanProcessedBank);

  const chartSeries = chartData.map((i) => i.volume);

  return (

    <>
      <CardHeader title={title} subheader={subheader} />
      <StyledChartWrapperBig dir="ltr">
        <ReactApexChart type="pie" series={chartSeries} options={chartOptions(chartColors, chartLabels, theme)} height={280} />
      </StyledChartWrapperBig>
    </>
  );
}