import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import Branches from "../../Master/branches";
import { getData, patchData, postData } from "../../webService/webService";
import MASTERROUTES from "../../API_Routes/Master/Master_Routers";
import USERSROUTES from "../../API_Routes/User/User_Routers";
import MISROUTES from "../../API_Routes/MIS/MIS_Routers";

function MISComponent({ Action, handleadduserClose }) {
  console.log(Action, "Action");
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const Branch = sessionStorage.getItem("Branch");
  const accessToken = sessionStorage.getItem("Token");
  const [CurrentLoanProcessedBank, setCurrentLoanProcessedBank] = useState();
  const [TypeOfLoan, setTypeOfLoan] = useState();
  const [ExistingLoanProcessedBankList, setExistingLoanProcessedBankList] = useState();
  const [MIS_StatusList, setMIS_StatusList] = useState();
  const [isValid, setIsValid] = useState(true);
  const [disableRemarks, setDisableRemarks] = useState(true);
  const [Remarks, setRemarks] = useState('');
  const [statusChangeCount, setStatusChangeCount] = useState(0);

  const [formData, setFormData] = React.useState({
    customerName: "",
    teleCallerName: "",
    vehicleModel: "",
    currentLoanProcessedBank: "",
    currentLoanProcessedBank_History: [],
    existingLoanProcessedBank: "",
    typeOfLoan: "",
    status: "",
    MIS_Status_History: [],
    mobileNumber: "",
    place: "",
    remarks: "",
    amount: "",
    branch: Branch !== "undefined" ? Branch : "",
    fileProcessedDate: "",
  });

  const handleChange = (event) => {

    const { name, value } = event.target;

    if (name === 'status') {
      setStatusChangeCount(statusChangeCount + 1)
      const MIS_Status_HistoryObj = {
        name: value,
        bank: formData?.currentLoanProcessedBank,
        remarks: Remarks,
        date: new Date(),
        handledBy: isAuthenticated === 'Super-Admin' ? isAuthenticated : Branch
      }
      setDisableRemarks(false);
      if (Action) {
        if( statusChangeCount > 0 ){
          formData.MIS_Status_History.pop();
          formData.MIS_Status_History.push(MIS_Status_HistoryObj);
        }
        if( statusChangeCount === 0 ){
          formData.MIS_Status_History.push(MIS_Status_HistoryObj);
        }
      }
      else {
        formData.MIS_Status_History = [MIS_Status_HistoryObj];
      }
      formData.status = value;
      setFormData({ ...formData });
    }

    if (name === 'remarks') {
      setRemarks(value)
      if (Action && formData?.MIS_Status_History?.length > 0) {
        const lastStatus = formData.MIS_Status_History.pop()
        lastStatus.remarks = value;
        formData.MIS_Status_History.push(lastStatus)
      }
      if (!Action && formData?.MIS_Status_History?.[0]?.name) {
        formData.MIS_Status_History[0].remarks = event?.target?.value;
      }
      setFormData({ ...formData })
    }

    if (name === 'currentLoanProcessedBank') {
      const currentLoanProcessedBankObj = {
        name: value,
        date: new Date(),
        handledBy: isAuthenticated === 'Super-Admin' ? isAuthenticated : Branch
      }
      if (Action) {
        formData.currentLoanProcessedBank_History.push(currentLoanProcessedBankObj);
      }
      else {
        formData.currentLoanProcessedBank_History = [currentLoanProcessedBankObj];
      }
      formData.currentLoanProcessedBank = value;
      setFormData({ ...formData });
    }
    else {
      // Validate mobile number if the input field is the mobileNumber
      if (name === 'mobileNumber') {
        const mobileRegex = /^[0-9]{0,10}$/; // Allows up to 10 digits only
        if (mobileRegex.test(value)) {
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          setIsValid(value.length === 10);
        }
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    console.log('MIS data', formData)
  }, [formData])

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (Action) {
        await patchData(MISROUTES.UPDATEMIS(Action._id), formData, accessToken);
        handleadduserClose();
      } else {
        await postData(MISROUTES.ADDMIS, formData, accessToken);
        setRemarks('')
      }
      setFormData({
        customerName: "",
        teleCallerName: "",
        vehicleModel: "",
        currentLoanProcessedBank: "",
        existingLoanProcessedBank: "",
        typeOfLoan: "",
        status: "",
        mobileNumber: "",
        place: "",
        remarks: "",
        amount: "",
        branch: Branch !== "undefined" ? Branch : "",
        fileProcessedDate: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an error submitting the form. Please try again.");
    }
  };

  const fetchDropDownData = (data) => {
    getData(MASTERROUTES.GETLIST(data), null, accessToken, false)
      .then((res) => {
        if (data === "CurrentLoanProcessedBank") {
          setCurrentLoanProcessedBank(res?.data?.masterList);
        }
        if (data === "TypeOfLoan") {
          setTypeOfLoan(res?.data?.masterList);
        }
        if (data === "ExistingLoanProcessedBankList") {
          setExistingLoanProcessedBankList(res?.data?.masterList);
        }
        if (data === "MIS_StatusList") {
          setMIS_StatusList(res?.data?.masterList);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchDropDownData("CurrentLoanProcessedBank");
    fetchDropDownData("TypeOfLoan");
    fetchDropDownData("ExistingLoanProcessedBankList");
    fetchDropDownData("MIS_StatusList");
  }, []);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
      },
    },
    MenuListProps: {
      style: {
        maxHeight: 200,
      },
    },
  };

  const [EmployeeList, setEmployeeList] = useState();
  const [TotalEmployeeList, setTotalEmployeeList] = useState(1);

  const fetchdata = () => {
    const params = {
      page: 1,
      limit: TotalEmployeeList,
      role: "Employee",
      branch: formData.branch,
    };
    getData(USERSROUTES.GETALLUSER, params, accessToken, false)
      .then((res) => {
        if (res.status === 404) {
          setEmployeeList();
        } else {
          setEmployeeList(res.data[0].paginatedResults);
          setTotalEmployeeList(res.data[0].totalPages);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (TotalEmployeeList > 1) {
      fetchdata();
    }
  }, [TotalEmployeeList]);

  useEffect(() => {
    fetchdata();
  }, [formData.branch]);

  const clearData = () => {
    setDisableRemarks(true);
    setFormData({
      customerName: "",
      teleCallerName: "",
      vehicleModel: "",
      currentLoanProcessedBank: "",
      existingLoanProcessedBank: "",
      typeOfLoan: "",
      status: "",
      mobileNumber: "",
      place: "",
      remarks: "",
      amount: "",
      branch: Branch !== "undefined" ? Branch : "",
      fileProcessedDate: "",
    });
  };

  useEffect(() => {
    if (Action) {
      setFormData({
        customerName: Action.customerName,
        teleCallerName: Action.teleCallerName,
        vehicleModel: Action.vehicleModel,
        currentLoanProcessedBank: Action.currentLoanProcessedBank,
        currentLoanProcessedBank_History: Action.currentLoanProcessedBank_History,
        existingLoanProcessedBank: Action.existingLoanProcessedBank,
        typeOfLoan: Action.typeOfLoan,
        status: Action.status,
        MIS_Status_History: Action.MIS_Status_History,
        mobileNumber: Action.mobileNumber,
        place: Action.place,
        remarks: Action.remarks,
        amount: Action.amount,
        branch: Action.branch,
        fileProcessedDate: Action.fileProcessedDate,
      });
    }
  }, [Action]);
  
  return (
    <>
      <Helmet>
        <title>Add MIS</title>
      </Helmet>
      <ToastContainer limit={3} />
      {!Action && <h5 className="title-content-common">Add MIS Reports</h5>}
      {Action && <h5 className="title-content-common"> Edit MIS</h5>}
      <Card className={`${!Action ? "p-4 main-card-box" : "p-4"}`}>
        <form onSubmit={handleSubmit}>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4 px-2">
              <TextField
                label="Customer Name"
                name="customerName"
                value={formData.customerName}
                onChange={handleChange}
                fullWidth
                size="small"
                margin="normal"
              />
            </div>
            {isAuthenticated !== "Branch-Admin" && (
              <div className="col-4 px-2">
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel style={{ background: "white" }}>
                    Branch
                  </InputLabel>
                  <Select
                    label="Branch"
                    name="branch"
                    value={formData.branch}
                    onChange={handleChange}
                    MenuProps={MenuProps}
                  >
                    {Branches.map((branch) => (
                      <MenuItem key={branch} value={branch}>
                        {branch}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            <div className="col-4 px-2">
              <FormControl fullWidth margin="normal" size="small">
                <InputLabel style={{ background: "white" }}>
                  Tele Caller Name
                </InputLabel>
                <Select
                  label="Tele"
                  name="teleCallerName"
                  value={formData.teleCallerName}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                >
                  {EmployeeList?.map((employee, index) => (
                    <MenuItem
                      key={index}
                      value={`${employee.firstName} ${employee.lastName}`}
                    >
                      {employee.firstName} {employee.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-4  px-2">
              <TextField
                label="Vehicle Model"
                name="vehicleModel"
                value={formData.vehicleModel}
                onChange={handleChange}
                fullWidth
                size="small"
                margin="normal"
              />
            </div>
            <div className="col-4 px-2">
              <FormControl fullWidth margin="normal" size="small">
                <InputLabel style={{ background: "white" }}>
                  Current Loan Processed Bank
                </InputLabel>
                <Select
                  name="currentLoanProcessedBank"
                  value={formData.currentLoanProcessedBank}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                >
                  {CurrentLoanProcessedBank?.map((CurrentLoanProcessedBank) => (
                    <MenuItem
                      key={CurrentLoanProcessedBank}
                      value={CurrentLoanProcessedBank}
                    >
                      {CurrentLoanProcessedBank}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-4 px-2">
              <FormControl fullWidth margin="normal" size="small">
                <InputLabel style={{ background: "white" }}>
                  Existing Loan Processed Bank
                </InputLabel>
                <Select
                  name="existingLoanProcessedBank"
                  value={formData.existingLoanProcessedBank}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                >
                  {ExistingLoanProcessedBankList?.map(
                    (ExistingLoanProcessedBankList) => (
                      <MenuItem
                        key={ExistingLoanProcessedBankList}
                        value={ExistingLoanProcessedBankList}
                      >
                        {ExistingLoanProcessedBankList}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="col-4 px-2">
              <FormControl fullWidth margin="normal" size="small">
                <InputLabel style={{ background: "white" }}>
                  Type of Loan
                </InputLabel>
                <Select
                  MenuProps={MenuProps}
                  name="typeOfLoan"
                  value={formData.typeOfLoan}
                  onChange={handleChange}
                >
                  {TypeOfLoan?.map((TypeOfLoan) => (
                    <MenuItem key={TypeOfLoan} value={TypeOfLoan}>
                      {TypeOfLoan}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-4 px-2">
              <FormControl fullWidth margin="normal" size="small">
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                >
                  {MIS_StatusList?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-4 px-2">
              <TextField
                label="Mobile Number"
                name="mobileNumber"
                size="small"
                value={formData?.mobileNumber}
                onChange={handleChange}
                fullWidth
                margin="normal"
                style={{
                  borderColor: isValid || formData?.mobileNumber?.length === 0 ? 'initial' : 'red',
                }}
              />
              {!isValid && formData?.mobileNumber?.length > 0 && (
                <span style={{ color: 'red', marginLeft: '10px' }}>
                  Must be a 10-digit number
                </span>
              )}
              {formData?.mobileNumber?.length > 10 && (
                <span style={{ color: 'red', marginLeft: '10px' }}>
                  Must be a 10-digit number
                </span>
              )}
            </div>
            <div className="col-4 px-2">
              <TextField
                label="Place"
                name="place"
                size="small"
                value={formData.place}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-4 px-2">
              <TextField
                label="Amount"
                name="amount"
                size="small"
                value={formData.amount}
                onChange={handleChange}
                type="number"
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-4 px-2">
              <TextField
                label="File Processed Date"
                name="fileProcessedDate"
                value={formData.fileProcessedDate?.split('T')[0]}
                onChange={handleChange}
                type="date"
                fullWidth
                size="small"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-12 px-2 mb-3">
              <TextField
                label="Remarks"
                name="remarks"
                size="small"
                value={Remarks}
                onChange={handleChange}
                fullWidth
                margin="normal"
                multiline
                hidden={disableRemarks}
                rows={4}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {!Action && (
              <div className="col-1 mx-3">
                <Button
                  variant="outlined"
                  className="ms-2"
                  onClick={clearData}
                  fullWidth
                >
                  Clear
                </Button>
              </div>
            )}
            {Action && (<div className="col-1 mx-3">
              <Button
                variant="outlined"
                className="ms-2"
                onClick={() => handleadduserClose(true)}
                fullWidth
              >
                Close
              </Button>
            </div>
            )}
            <div className="col-1">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </Card>
    </>
  );
}

export default MISComponent;
